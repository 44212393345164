import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Offline from '../components/Offline.vue'

Vue.use(VueRouter)

const routes = [
    // random

    // --- Login ---
    {
        path: '/',
        name: 'auth',
        component: Login,
    },

    // --- Offline ---
    {
        path: '/off',
        name: 'offline',
        component: Offline,
    },

    // --- admin ---
    // -- base
    {
        path: '/ad',
        name: 'admin',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/Admin.vue')
    },
    // -- Opciones
    // 1.- Ajustes
    {
        path: '/ad/cf',
        name: 'settings',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/Settings.vue')
    },
    // 2.- Periodo escolar
    {
        path: '/ad/cl',
        name: 'period',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/Period.vue')
    },
    // 2.n Contenidos del periodo <---
    {
        path: '/ad/cl/cm',
        name: 'curriculum',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/bases/Curriculum.vue')
    },
    {
        path: '/ad/cl/tc',
        name: 'teachers',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/bases/Teachers.vue')
    },
    {
        path: '/ad/cl/ls',
        name: 'lessons',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/bases/Lessons.vue')
    },
    // -->
    // 3.- Alumnos
    {
        path: '/ad/ins',
        name: 'inscriptions',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/Inscriptions.vue')
    },
    // 3.n Contenidos de las inscripciones (alumnos) <---
    {
        path: '/ad/ins/sr/:id',
        name: 'Search',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/helpers/Search.vue')
    },
    {
        path: '/ad/ins/in',
        name: 'Inscription',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/helpers/Inscription.vue')
    },
    // --->
    // 3.- Contenidos
    {
        path: '/ad/cn',
        name: 'contents',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/Contents.vue')
    },
    // 4.- Datos
    {
        path: '/ad/dt',
        name: 'reports',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/Reports.vue')
    },

    // --- Operatividad (docentes) ---
    // -- Base
    {
        path: '/op',
        name: 'operative',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/Operative.vue')
    },
    // -- Clases
    {
        path: '/op/ls/:id',
        name: 'lesson',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/operative/Lesson.vue')
    },
    // - Asistencia
    {
        path: '/op/ls/:id/as/:assist',
        name: 'assist',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/operative/lesson/Assist.vue')
    },
    // - Evidencia
    {
        path: '/op/ls/:id/ev/:evidence',
        name: 'evidence',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/operative/lesson/Evidence.vue')
    },
    // - Alumnos en riesgo
    {
        path: '/op/ls/:id/rk',
        name: 'risk',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/operative/lesson/Risk.vue')
    },
    // - Calificación parcial/final
    {
        path: '/op/ls/:id/rt',
        name: 'ratings',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/operative/lesson/Ratings.vue')
    },

    // --- Servicios internos (plataforma y tutores) ---
    // -- Alumnos (plataforma y calificaciones)
    // - Autenticación
    {
        path: '/alumnos',
        name: 'Auth',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/services/Auth.vue')
    },

    // -- Plataforma
    {
        path: '/alumnos/:id',
        name: 'Student',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/services/students/Student.vue')
    },

    // -- Plataforma de contenidos
    {
        path: '/alumnos/:id/:lesson',
        name: 'Classroom',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/services/students/Classroom.vue')
    },
    // -->

    // --- Servicios externos (sin validación) ---
    {
        path: '/preinscripciones',
        name: 'Preinscription',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/admin/helpers/Preinscription.vue')
    },
    {
        path: '/validar-constancia/:student/:period',
        name: 'Constancy',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/public/Constancy.vue')
    },
    {
        path: '/validar-recibo/:inscription',
        name: 'Receipt',
        component: () =>
            import( /* webpackChunkName: "components" */ '../components/infrastructure/public/Receipt.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router